<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-card class="mx-auto" rounded="lg" elevation="0" max-width="100%">
        <v-card-title
          ><v-icon color="green" x-large dark> mdi-plus-circle-outline </v-icon
          ><span class="text-h4 font-weight-bold mt-1 green--text">
            Pending TMPs
          </span>
          <span
            class="text-subtitle-1 font-weight-normal mt-3 ml-2 black--text"
          >
            Total
          </span>
          <v-chip class="ml-2 mt-2" color="green" small dark>
            {{ info.total_tpms }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-progress-linear color="green" value="100"></v-progress-linear
        ></v-card-title>
        <v-container>
          <v-data-table
            item-key="event_id"
            :headers="headers"
            :items="info.data"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait"
            group-by="tmp"
            show-group-by
          >
            <template v-slot:[`item.type`]="{ item }">
              <v-chip :color="getColor(item.type)" dark>
                {{ item.type }}
              </v-chip>
            </template>
            <template v-slot:[`item.solved`]="{ item }">
              <v-switch v-model="item.solved" inset></v-switch>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { eventBus } from "../../main";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "ContainersTable",
  data: () => ({
    search: "",
    isLoading: false,
    info: {
      success: false,
      total_tpms: 0,
      data: [],
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: "TMP",
          align: "start",
          sortable: true,
          value: "tmp",
        },
        {
          text: "Event ID",
          sortable: true,
          groupable: false,
          value: "event_id",
        },
        { text: "Type", sortable: true, groupable: false, value: "type" },
        {
          text: "Sequence",
          sortable: true,
          groupable: false,
          value: "sequence",
        },
        {
          text: "Error",
          sortable: true,
          groupable: false,
          value: "error",
        },
        {
          text: "Driver",
          sortable: true,
          groupable: true,
          value: "driver_name",
        },
        { text: "Solved", sortable: true, groupable: false, value: "solved" },
      ];
    },
  },
  methods: {
    getAllContainers: function () {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_GET_ALL_TMPS)
        .then((response) => {
          if (response.data.success) {
            this.info = response.data;
            this.isLoading = false;
          } else {
            this.info = null;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getColor: function (type) {
      switch (type) {
        case "DROP":
          return "red";
        case "HOOK":
          return "orange";
        case "DELIVER":
          return "light-green ";
        case "PICKUP":
          return "green ";
        case "MOUNT":
          return "green";
        case "DISMOUNT":
          return "green";
        default:
          return "gray";
      }
    },
  },
  mounted() {
    this.getAllContainers();
  },
  created() {
    eventBus.$on("reloadTable", () => {
      this.info.data = [];
      this.getAllContainers();
    });
  },
};
</script>

<style scoped>
.customer-app {
  width: 100%;
  height: 100%;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
