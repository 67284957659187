<template>
  <v-app>
    <v-app-bar app color="gray" dark elevation="0" dense>
      <div class="d-flex align-center">
        <v-img
          alt="dockma"
          class="shrink mt-1"
          contain
          min-width="100"
          src="/BFsync.png"
          width="170"
        />
      </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="reloadTable"
        ><v-icon>mdi-reload</v-icon>
        <span class="ml-2 hidden-sm-and-down"> Reload </span></v-btn
      >
    </v-app-bar>

    <v-main>
      <!-- <SnackBarService /> -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { eventBus } from "./main";

export default {
  name: "App",

  data: () => ({
    items: [{ title: "Lakme yard" }],
  }),
  methods: {
    reloadTable: function () {
      eventBus.$emit("reloadTable");
    },
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
