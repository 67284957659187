import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#509BCC",
        gray: "#3F3F3F",
        green: "#50CCAB",
      },
    },
  },
});
