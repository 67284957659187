<template>
  <div class="home">
    <ContainersList />
  </div>
</template>

<script>
import ContainersList from "../components/ContainersList";

export default {
  name: "ContainersListView",

  components: {
    ContainersList,
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
