<template>
  <div class="grey lighten-3 customer-app">
    <v-container>
      <ContainersTable />
    </v-container>
  </div>
</template>

<script>
import ContainersTable from "./Containers/ContainersTable.vue";
// import { eventBus } from "../main";

export default {
  name: "ContainersList",
  components: {
    ContainersTable,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.customer-app {
  width: 100%;
  height: 100%;
}
</style>
